import React from 'react';

import Layout from '../marketing/landing/components/common/layout/layout';
import { isSalonzone } from '../utils';
import messages from '../data/messages/sk';
import salonzoneMessages from '../data/messages/salonzone/sk';

const IndexPage = ({ location }) => (
  <Layout
    location={location}
    locale="sk"
    i18nMessages={isSalonzone ? salonzoneMessages : messages}
  />
);

export default IndexPage;
